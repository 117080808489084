import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWrap from "../../../components/ImageWrap";
import { BASE_URL } from "../../../config";
import { saveUserDataInSession } from "../../../hooks/session-data";

const Classes = () => {
  const [classesList, setClassesList] = useState([]);

  const handleClassArtist = (genre, label) => {
    saveUserDataInSession("genre", genre);
    saveUserDataInSession("label", label);
  };

  useEffect(() => {
    const url = `${BASE_URL}/api/v1/regular-classes`;
    axios
      .get(url)
      .then((response) => {
        setClassesList(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <h2 className="dashboard-heading title_font title_underline">
        REGULAR CLASSES
      </h2>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {classesList.map(
          (data, index) =>
            data.img && (
              <Link
                to="/user/artist"
                key={index}
                onClick={() => handleClassArtist(data.slug, data.label)}
                className="m-12"
              >
                <div
                  className="card elevated_deep clickable ai-center"
                  style={{
                    width: 250,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <ImageWrap data={data.img} />
                  <h5
                    className="h5_font_family"
                    style={{
                      position: "relative",
                      zIndex: "3",
                      paddingTop: 13,
                    }}
                  >
                    {data.label}
                  </h5>
                </div>
              </Link>
            )
        )}
      </div>
    </>
  );
};

export default Classes;
