import { Navigate } from "react-router-dom";
import { auth } from "./config";

export const PrivateRoute = ({ children }) => {
  const isAuthenticated = auth;

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/" />;
};
