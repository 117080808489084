import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageWrap from "../components/ImageWrap";
import { BASE_URL } from "../config";
import styles from "../style/dashboard.module.css";

const ClassCurriculum = () => {
  const [classesList, setClassesList] = useState([]);

  useEffect(() => {
    const url = `${BASE_URL}/api/v1/classes-curriculum`;
    axios
      .get(url)
      .then((response) => {
        setClassesList(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <h2 className="dashboard-heading title_font title_underline">
        CLASS CURRICULUM
      </h2>
      <div
        className="jc-center p-24"
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {classesList.map((data, index) => (
          <div to="/user/artist" key={index}>
            <div
              className={`card elevated_deep m-24 ai-center ${styles.lang_button}`}
              style={{ width: 250 }}
            >
              <ImageWrap dataCurriculum={data.pdf_cover_pic} />
              <div
                style={{
                  display: "flex",
                  position: "relative",
                }}
              >
                <a
                  style={{
                    right: 50,
                  }}
                  className="btn-get-started mx-1"
                  target="_blank"
                  href={`${data.info_link_h}`}
                >
                  Hindi
                </a>
                <a
                  style={{
                    left: 40,
                  }}
                  className="btn-get-started mx-1"
                  target="_blank"
                  href={`${data.info_link_e}`}
                >
                  English
                </a>
              </div>
              <h5
                className="h5_font_family"
                style={{ position: "relative", zIndex: 100 }}
              >
                {data.title}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ClassCurriculum;
