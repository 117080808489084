import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import OtpInput from "react-otp-input";
import { BASE_URL } from "../config";
import style from "../style/dashboard.module.css";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { saveUserDataInSession } from "../hooks/session-data";

const headers = {
  "Content-Type": "multipart/form-data",
};

const Login = () => {
  const navigate = useNavigate();
  const [verification, setVerification] = useState(false);
  const [guest, setGuest] = useState(true);
  const [otp, set_otp] = useState("");
  const [userId, setUserId] = useState("");
  const [mobileVerify, setMobileVerify] = useState(true);

  let location = useLocation();

  async function login() {
    if (!validateUserId(userId)) return;

    const body = isUserIdMobile(userId)
      ? {
          username: userId,
        }
      : {
          loginType: "email",
          email: userId,
        };

    axios
      .post(
        `${BASE_URL}/api/v1/${
          isUserIdMobile(userId) ? "existence" : "send-mail"
        }`,
        body,
        { headers }
      )
      .then(function (response) {
        if (isUserIdMobile(userId)) {
          if (response.data.status.success) {
            if (response.data.status.success.exist) {
              setVerification(true);
            } else {
              navigate("/register");
            }
          }
        } else {
          if (response.data.status) setVerification(true);
          else navigate("/register");
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        alert("Unexpected error occured");
      });
  }

  function isUserIdMobile(userId) {
    const phoneRegex = /^\d{10}$/;
    if (phoneRegex.test(userId)) return true;
    else {
      setMobileVerify(false);
      return false;
    }
  }

  function validateUserId(user) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (emailRegex.test(user)) {
      return true;
    } else if (phoneRegex.test(user)) {
      return true;
    }
    return false;
  }

  async function verify() {
    let body = mobileVerify
      ? {
          otp: otp,
          username: userId,
        }
      : {
          otp: otp,
          username: userId,
          loginType: "email",
        };

    axios
      .post(
        `${BASE_URL}/api/v1/${
          mobileVerify ? "validate-otp" : "validate-email-otp"
        }`,
        body,
        { headers }
      )
      .then(function (response) {
        if (!response.data.status.success) alert(response.data.status.message);
        else {
          const email = response.data.data.user.email;
          const full_name = response.data.data.user.full_name;
          saveUserDataInSession("r2rToken", response.data.data.bearer_token);
          saveUserDataInSession("r2rUserName", full_name);
          saveUserDataInSession("r2rUserEmail", email);
          saveUserDataInSession(
            "user",
            JSON.stringify({
              userId: email,
              name: full_name,
            })
          );
          navigate("/user/home");
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Unexpected error occured");
      });
  }

  useEffect(() => {
    if (location.state) {
      const {
        state: { guest, verification, type, user },
      } = location;
      setGuest(guest);
      setVerification(verification);
      setMobileVerify(type);
      setUserId(user);
    }
  }, [location]);

  useEffect(() => {
    navigate(location.pathname, {});
  }, []);

  if (guest) {
    return (
      <div
        className="bg-container ai-center jc-center"
        style={styles.container}
      >
        <img
          src={require("src/assets/app_logo.png").default}
          style={styles.app_logo}
        />
        <div class="mainCard">
          <div class="imgWrap" style={styles.wrapImg}>
            <img
              src={require("src/assets/Finl.jpg").default}
              alt=""
              style={styles.image}
            />
          </div>
          <div className="card ai-center elevated" style={styles.card}>
            <h3>
              Hello, <br /> Welcome to Routes 2 Roots
            </h3>

            <button
              type="primary"
              style={styles.button}
              onClick={() => navigate("/guest")}
            >
              Join Live
            </button>
            <button
              type="secondry"
              style={styles.button}
              onClick={() => setGuest(false)}
            >
              Sign In
            </button>
            <br />
            <button
              type="secondary"
              className={style.register_button}
              onClick={() => navigate("/register")}
            >
              Create a new account
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    if (verification)
      return (
        <div
          className="bg-container ai-center jc-center"
          style={styles.container}
        >
          <img
            src={require("src/assets/app_logo.png").default}
            style={styles.app_logo}
          />
          <div class="mainCard">
            <div class="imgWrap" style={styles.wrapImg}>
              <img
                src={require("src/assets/Finl.jpg").default}
                alt=""
                style={styles.image}
              />
            </div>
            <div className="card ai-center elevated" style={styles.card}>
              <div style={styles.header}>
                <div
                  style={{ padding: 4 }}
                  className="clickable"
                  onClick={() => setVerification(false)}
                >
                  {"←"}
                </div>
              </div>
              <h1>Verify Account</h1>
              <h5 className="spacing_sm" align="center">
                We have sent the verification code to <br />
                You
              </h5>
              <h3>{userId}</h3>
              <br />
              <OtpInput
                value={otp}
                onChange={set_otp}
                numInputs={4}
                inputStyle={{
                  padding: 12,
                  margin: 12,
                  fontSize: 20,
                  border: "1px solid #C7C7C7",
                  borderRadius: 5,
                }}
              />
              <button type="primary" style={styles.button} onClick={verify}>
                Verify OTP
              </button>
              <button type="secondry" style={styles.button}>
                Resend OTP
              </button>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div
          className="bg-container ai-center jc-center"
          style={styles.container}
        >
          <img
            src={require("src/assets/app_logo.png").default}
            style={styles.app_logo}
          />
          <div class="mainCard">
            <div class="imgWrap" style={styles.wrapImg}>
              <img
                src={require("src/assets/Finl.jpg").default}
                alt=""
                style={styles.image}
              />
            </div>
            <div className="card ai-center elevated" style={styles.card}>
              <div style={styles.header}>
                <div
                  style={{ padding: 4 }}
                  className="clickable"
                  onClick={() => setGuest(true)}
                >
                  {"←"}
                </div>
              </div>
              <h1>Login Account</h1>
              <h5 className="spacing_sm" align="center">
                Enter your Phone Number or Email ID to proceed
              </h5>
              <br />
              <input
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                className="input"
                placeholder="Phone Number / Email ID"
                style={styles.input}
              />
              <br />
              <button
                type={validateUserId(userId) ? "primary" : "disabled"}
                style={styles.button}
                onClick={login}
              >
                Request OTP
              </button>
              <br />
              <div style={styles.orLine}>---------- OR ----------</div>
              <br />
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                  saveUserDataInSession(
                    "r2rToken",
                    credentialResponse.credential
                  );
                  let decodedJwt = jwt_decode(credentialResponse.credential);
                  saveUserDataInSession("r2rUserName", decodedJwt.given_name);
                  saveUserDataInSession("r2rUserEmail", decodedJwt.email);
                  saveUserDataInSession(
                    "user",
                    JSON.stringify({
                      userId: decodedJwt.email,
                      name: decodedJwt.given_name,
                    })
                  );
                  navigate("/user/home");
                  window.location.reload();
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                useOneTap
              />
              <br />
              <br />
            </div>
          </div>
        </div>
      );
  }
};

const styles = {
  container: {
    overflow: "hidden",
    backgroundColor: "#E4E4E4",
  },
  app_logo: {
    position: "absolute",
    top: 32,
    right: 32,
    height: 32,
  },
  wrapImg: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  card: {
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  input: {
    marginTop: 12,
  },
  button: {
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
    padding: 15,
  },
  header: {
    position: "absolute",
    top: "1rem",
    left: 0,
    width: "100%",
    width: 300,
    fontSize: 24,
  },
  orLine: {
    fontSize: "15px",
  },
  app_logo: {
    position: "absolute",
    top: 32,
    right: 32,
    height: 32,
  },
};

export default Login;
