import React, { useEffect, useState } from "react";
// import Slider from "react-slick/lib/slider";
import folder from "../assets/home/folder.png";
import meeting from "../assets/home/round-table.png";
import competition from "../assets/home/competition.png";
// import bookImg from "../assets/home/books bg.png";
import { useNavigate } from "react-router-dom";
import { auth, BASE_URL } from "../config";
import axios from "axios";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  const [data, setData] = useState({});

  useEffect(() => {
    const url = `${BASE_URL}/api/v1/banners`;
    const headers = { authorization: `${auth}` };
    axios
      .get(url, { headers })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className=" p-24">
        {/* <div className="">
          <Slider {...settings}>
            <div style={{ margin: "12px auto" }}>
              <img style={{ margin: "12px auto" }} width="60%" src={bookImg} />
            </div>
            <div style={{ margin: "12px auto" }}>
              <img style={{ margin: "12px auto" }} width="60%" src={bookImg} />
            </div>
          </Slider>
        </div> */}

        <div
          className="jc-center"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div
            className="card elevated_deep m-24 clickable ai-center"
            style={style.classCard}
            onClick={() => navigate("/user/archive")}
          >
            <img
              className="zoom-in"
              src={folder}
              width="100px"
              height="100px"
            />
            <h5 className="title_font" style={style.title}>
              Archive
            </h5>
          </div>
          <div
            className="card elevated_deep m-24 clickable ai-center"
            style={style.classCard}
            onClick={() => navigate("/user/workshops")}
          >
            <img
              className="zoom-in"
              src={meeting}
              width="100px"
              height="100px"
            />
            <h5 className="title_font" style={style.title}>
              {" "}
              Workshop
            </h5>
          </div>
          <a
            className="card elevated_deep m-24 clickable ai-center"
            style={style.classCard}
            href="https://routes2roots.com/competition"
            target="_blank"
          >
            <img
              className="zoom-in"
              src={competition}
              width="100px"
              height="100px"
            />
            <h5 className="title_font " style={style.title}>
              Competition
            </h5>
          </a>
        </div>
      </div>
    </>
  );
};
const style = {
  classCard: {
    width: 250,
    height: 250,
    textAlign: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: 30,
    paddingTop: 30,
    color: "#2d8b82",
  },
};
export default Home;
