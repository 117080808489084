import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { getAuthtoken } from "./Auth";
import Controls from "./Controls";
import ParticipantList from "./ParticipantList";
import status_config from "./status.json";

import useScreenOrientation from "../hooks/useOrientation";
import useBoundingClientRect from "../hooks/useBoundingClientRect";
import {
  loadUserDataFromSession,
  saveUserDataInSession,
} from "../hooks/session-data";

const meetUrl = process.env.MEETING_URL;
const mediaSettings = {
  receiveVideo: true,
  receiveAudio: true,
  receiveShare: false,
  sendVideo: true,
  sendAudio: true,
  sendShare: false,
};

var webex = {};
var flag = true;
var activeMeeting = null;
var current_controls = () => {};

function JoinMeet() {
  const [bound, setBoundingRect] = useState(undefined);
  const [controllerBound, setControllerBound] = useState(undefined);
  const [mediaStream, setMediaStream] = useState(undefined);
  const testRef = useRef(null);
  const controllerRef = useRef(null);
  const testBound = useBoundingClientRect(".test");

  // const [bound,setBound] = useState(testBound)
  useLayoutEffect(() => {
    const calculateComponentRect = () => {
      const componentRect = testRef.current?.getBoundingClientRect();
      const controllerRect = controllerRef.current?.getBoundingClientRect();
      console.log("height and widht " + controllerRect);
      setBoundingRect(componentRect);
      setControllerBound(controllerRect);
    };
    calculateComponentRect();
    window.addEventListener("resize", calculateComponentRect);
    return () => {
      window.removeEventListener("resize", calculateComponentRect);
    };
  }, [testRef.current]);

  useEffect(() => {
    if (mediaStream) {
      activeMeeting.changeVideoLayout("Single", {
        main: {
          height: bound?.height - controllerBound?.height,
          width: bound?.width,
        },
      });
      const frame = document.getElementsByTagName("iframe")[0];
      if (frame) {
        console.log(frame);
        document.body.removeChild(frame);
      }
    }
  }, [mediaStream]);

  const orientation = useScreenOrientation();

  const [status, set_status] = React.useState("joining");
  const [joinFlag, setJoinFlag] = React.useState(false);
  const [hostId, setHostId] = React.useState("");
  const [selfId, setSelfId] = React.useState("");
  const [members, setMembers] = React.useState({});
  const local_preferences = loadUserDataFromSession("preferences") || "{}";
  const [config, setConfig] = React.useState({
    preferences: JSON.parse(local_preferences),
    devices: [],
  });
  const [controls, set_controls] = React.useState({
    video: true,
    audio: true,
    muted: false,
    handraise: false,
    participants: false,
    settings: false,
    joined: false,
  });
  current_controls = () => controls;
  const setControls = (key, value) => {
    let obj = { ...current_controls() };
    obj[key] = value;
    set_controls({ ...obj });
  };

  //authorization
  async function authorizeGuest() {
    const token = await getAuthtoken();
    if (token) {
      webex.authorization
        .requestAccessTokenFromJwt({ jwt: `Bearer ${token}` })
        .then((resp) => {
          initMeeting(meetUrl);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      set_status("unauthorized");
    }
  }

  //media setup
  function getMediaDevices() {
    activeMeeting.getDevices().then((deviceInfos) => {
      const obj = { ...config };
      obj.devices = deviceInfos;
      setConfig(obj);
    });
  }
  function addMedia(streams) {
    try {
      activeMeeting
        .addMedia({
          localShare: streams.localShare,
          localStream: streams.localStream,
          mediaSettings,
        })
        .then((mediaResponse) => {
          // do something once you know media has been completed
        });
    } catch (e) {
      alert(e);
    }
  }
  function setMedia(media) {
    setMediaStream(media);
    console.log("media", media);
    if (media.type === "local") {
      document.getElementById("local").srcObject = media.stream;
    } else if (media.type === "remoteVideo") {
      document.getElementById("remoteVideo").srcObject = media.stream;
    } else if (media.type === "remoteAudio") {
      document.getElementById("remoteAudio").srcObject = media.stream;
    }
  }

  //join meeting
  async function JoinMeeting() {
    setSelfId(activeMeeting.members["selfId"]);
    let controls_obj = {
      ...current_controls(),
      audio: !activeMeeting.remoteMuted,
      muted: !activeMeeting.unmuteAllowed,
    };
    set_controls(controls_obj);
    setJoinFlag(true);
  }
  async function initMeeting(destination) {
    await webex.meetings.register();
    webex.meetings.create(destination).then((meeting) => {
      console.log("meeting", meeting);
      activeMeeting = meeting;
      getMediaDevices();
      meeting.on("meeting:reconnectionStarting", (i) => {
        console.log("meeting:reconnectionStarting", i);
      });
      meeting.on("meeting:reconnectionSuccess", (i) => {
        console.log("meeting:reconnectionSuccess", i);
      });
      meeting.on("meeting:reconnectionFailure", (i) => {
        console.log("meeting:reconnectionFailure", i);
      });
      meeting.on("meeting:self:lobbyWaiting", (i) => {
        set_status("waiting");
      });
      meeting.on("meeting:self:guestAdmitted", () => {
        JoinMeeting();
      });
      meeting.on("media:ready", setMedia);
      meeting
        .join()
        .then((joinResponse) => {
          console.log("joinResponse", joinResponse);
          if (meeting.meetingState === "ACTIVE" && !meeting.isUserUnadmitted) {
            JoinMeeting();
          } else if (meeting.meetingState === "INITIALIZING") {
            set_status("not_started");
          }
        })
        .catch((e) => {
          console.log(e);
        });
      //console.log(activeMeeting.inMeetingActions.get())
    });
  }

  //meeting events
  React.useEffect(() => {
    if (joinFlag) {
      let audio = {};
      let video = {};
      if (config.preferences.audio_input) {
        audio.deviceId = { exact: config.preferences.audio_input };
      }
      if (config.preferences.video_input) {
        video.deviceId = { exact: config.preferences.video_input };
      }
      activeMeeting
        .getMediaStreams(mediaSettings, { audio, video })
        .then(([localStream, localShare]) => {
          addMedia({ localStream, localShare });
          activeMeeting.on("meeting:self:mutedByOthers", (resp) => {
            set_controls({
              ...current_controls(),
              audio: !resp.payload.muted,
              muted: !resp.payload.unmuteAllowed,
            });
          });
          activeMeeting.members.on("members:update", (payload) => {
            console.log("members", activeMeeting.members);
            setMembers({ ...payload.full });
          });
          setControls("joined", true);
          set_status("joined");
        })
        .catch((e) => {
          console.log(e);
        });

      console.log(activeMeeting);
      console.log(webex.messages.list);
      window.webex = webex;
      window.meeting = activeMeeting;
    }
  }, [joinFlag]);

  //controls
  React.useEffect(() => {
    if (activeMeeting && !controls.joined) {
      activeMeeting.leave();
      set_status("left");
      setJoinFlag(false);
      webex.meetings.unregister();
    }
  }, [controls.joined]);
  React.useEffect(() => {
    if (controls.joined && controls.handraise) {
      activeMeeting.members.raiseOrLowerHand(selfId);
    }
  }, [controls.handraise]);
  React.useEffect(() => {
    if (controls.joined) {
      if (!members[selfId].isHandRaised) {
        setControls("handraise", false);
      }
      let ended_flag = true;
      Object.keys(members).map((id) => {
        if (members[id].status === "IN_MEETING") {
          ended_flag = false;
        }
      });
      if (ended_flag) {
        set_status("ended");
        setJoinFlag(false);
        webex.meetings.unregister();
      }
    }
  }, [members]);
  React.useEffect(() => {
    if (controls.joined) {
      if (controls.video) {
        activeMeeting.unmuteVideo();
      } else {
        activeMeeting.muteVideo();
      }
    }
  }, [controls.video]);
  React.useEffect(() => {
    if (controls.joined) {
      if (controls.audio) {
        activeMeeting.unmuteAudio();
      } else {
        activeMeeting.muteAudio();
      }
    }
  }, [controls.audio]);

  //main thread
  let ui_test = false;
  React.useEffect(() => {
    const getParamsFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get("userId");
      const name = urlParams.get("name");

      if (userId && name) {
        saveUserDataInSession(
          "user",
          JSON.stringify({
            userId,
            name,
          })
        );
      }
    };
    getParamsFromURL();
    const Webex = require(`webex`);
    webex = Webex.init();
    webex.once(`ready`, function () {
      if (flag && !ui_test) {
        flag = false;
        authorizeGuest();
      }
    });
    window.addEventListener("orientationchange", (e) => {
      //window.location.reload();
    });
    return () => {
      setControls("joined", false);
    };
  }, []);

  if (ui_test)
    return (
      <div
        className="container ai-center test"
        style={{
          backgroundColor: "black",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div className="flex-grow-1">
          <video
            id="remoteVideo"
            muted={true}
            autoPlay
            playsInline
            style={{
              ...styles.hero_video,
              width: bound?.width,
              height: bound?.height - controllerBound?.height,
            }}
          ></video>

          {controls.participants && !controls.settings && (
            <ParticipantList meeting={activeMeeting} {...{ selfId, members }} />
          )}
        </div>
        <div className="ai-center" style={styles.localwrap}>
          <div style={styles.localVideo}>
            <video
              height={70}
              id="local"
              muted={true}
              autoPlay
              playsInline
            ></video>
            {!controls.video && (
              <img
                src={require("src/assets/placeholder-video.png").default}
                style={styles.overlay}
              />
            )}
          </div>
          <img
            src={require("src/assets/app_logo.png").default}
            style={styles.app_logo}
          />
        </div>
        <div className="controller-bound">
          <Controls
            current={controls}
            onUpdate={setControls}
            settings={config}
          />

          <audio id="remoteAudio" autoPlay></audio>
        </div>
      </div>
    );

  if (!joinFlag) {
    return (
      <div
        className="container jc-center ai-center"
        style={{ backgroundColor: "#151718" }}
      >
        <img
          src={require("src/assets/app_logo.png").default}
          style={styles.app_logo_center}
        />
        <div style={{ color: "#fff", fontSize: 22 }}>
          {status_config[status]}
        </div>
      </div>
    );
  }

  return (
    <div
      className="container ai-center test"
      ref={testRef}
      style={{ backgroundColor: "black", height: "100vh", overflow: "hidden" }}
    >
      <div className="flex-grow-1 " style={{ width: "100%" }}>
        <video
          id="remoteVideo"
          muted={true}
          autoPlay
          playsInline
          style={{
            ...styles.hero_video,
            width: bound?.width,
            height: bound?.height - controllerBound?.height,
          }}
        ></video>

        {controls.participants && !controls.settings && (
          <ParticipantList meeting={activeMeeting} {...{ selfId, members }} />
        )}
      </div>
      <div className="ai-center" style={styles.localwrap}>
        <img
          src={require("src/assets/app_logo.png").default}
          style={styles.app_logo}
        />
        <div style={styles.localVideo}>
          <video
            height={70}
            id="local"
            muted={true}
            autoPlay
            playsInline
          ></video>
          {!controls.video && (
            <img
              src={require("src/assets/placeholder-video.png").default}
              style={styles.overlay}
            />
          )}
        </div>
      </div>
      <div ref={controllerRef} className="controller-bound">
        {controls.joined && (
          <Controls
            current={controls}
            onUpdate={setControls}
            settings={config}
          />
        )}
        <audio id="remoteAudio" autoPlay></audio>
      </div>
    </div>
  );
}

const styles = {
  hero_video: {
    objectFit: "contain",
    backgroundColor: "black",
    //backgroundColor: '#E4E4E4',
  },
  localwrap: {
    position: "absolute",
    top: 12,
    right: 12,
  },
  localVideo: {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: '#272727',
    borderRadius: 10,
    overflow: "hidden",
  },
  overlay: {
    position: "absolute",
    bottom: 0,
    height: 73,
    width: "100%",
    objectFit: "cover",
  },
  app_logo_center: {
    height: 44,
    marginBottom: 24,
  },
  app_logo: {
    marginBottom: 16,
    height: 18,
  },
};

export default JoinMeet;
