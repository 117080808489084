import React, { useState } from "react";
import playButton from "../assets/home/play.svg";
import close from "../assets/home/close-icon.png";
import { THUMBNAIL_URL, THUMBNAIL_URL_QUALITY } from "../config";
import Modal from "@mui/material/Modal";

const Video = ({ data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let newData = data.split("=");
  if (newData.length === 1) {
    newData = data.slice(17);
  } else {
    newData = newData[1].slice(0, 11);
  }

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }} className="clickable">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            cursor: "position",
            zIndex: 1,
          }}
          onClick={() => setModalIsOpen(true)}
        >
          <img
            src={playButton}
            alt="img"
            width="30px"
            height="30px"
            style={style.img}
          />
        </div>
        <img
          className="zoom-in"
          src={`${THUMBNAIL_URL}${newData}${THUMBNAIL_URL_QUALITY}`}
          alt="img"
          width="100%"
          height="100%"
        />
      </div>
      <Modal
        open={modalIsOpen}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div sx={{ ...styles }}>
          <div
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              background: "#000000cc",
            }}
          >
            <img
              src={close}
              alt="img"
              onClick={() => setModalIsOpen(false)}
              style={{
                color: "white",
                filter: "invert(1)",
                position: "absolute",
                right: 30,
                top: 40,
                width: 40,
                height: 40,
                cursor: "pointer",
              }}
            />
            <iframe
              style={{
                display: "block",
                margin: "40px auto",
              }}
              width="1100"
              height="600"
              src={`https://www.youtube.com/embed/${newData}?autoplay=${modalIsOpen}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const style = {
  playButton: {
    position: "absolute",
    width: "100%",
    height: "100%",
    cursor: "position",
  },
  img: {
    cursor: "pointer",
    padding: "5%",
    filter: "invert(1)",
    background: "rgb(210 116 125) ",
    position: "absolute",
    top: "42%",
    left: "42%",
    borderRadius: "10px",
  },
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  background: "#000000cc",
  pt: 2,
  px: 4,
  pb: 3,
};

export default Video;
