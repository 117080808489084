import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "../../../assets/home/classes.png";
import masterClasses from "../../../assets/home/master_classes.png";
import specialClasses from "../../../assets/home/special_classes.png";
import teacherClasses from "../../../assets/home/teacher_training.png";

const Archive = () => {
  const navigate = useNavigate();

  return (
    <div>
      {" "}
      <h2 className="dashboard-heading title_font title_underline">
        ARCHIVE CLASSES
      </h2>
      <div
        className="jc-center p-24"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div
          className="card-plan elevated_deep m-24 clickable ai-center"
          style={{
            width: 350,
            height: 350,
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => navigate("/user/archive-classes")}
        >
          <img
            className="zoom-in"
            src={classes}
            alt="img"
            width="100%"
            height="100%"
          />
        </div>
        <div
          className="card-plan elevated_deep m-24 clickable ai-center"
          style={{
            width: 350,
            height: 350,
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => navigate("/user/archive-master-classes")}
        >
          <img
            className="zoom-in"
            src={masterClasses}
            alt="img"
            width="100%"
            height="100%"
          />
        </div>
        <div
          className="card-plan elevated_deep m-24 clickable ai-center"
          style={{
            width: 350,
            height: 350,
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => navigate("/user/archive-special-classes")}
        >
          <img
            className="zoom-in"
            src={specialClasses}
            width="100%"
            alt="img"
            height="100%"
          />
        </div>
        <div
          className="card-plan elevated_deep m-24 clickable ai-center"
          style={{
            width: 350,
            height: 350,
            textAlign: "center",
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => navigate("/user/archive-teachers-classes")}
        >
          <img
            className="zoom-in"
            src={teacherClasses}
            width="100%"
            alt="img"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default Archive;
