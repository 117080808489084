import React from 'react';
import Settings from './Settings';
import './controls.css';

import audioIcon from 'src/assets/controls/audio.svg';
import videoIcon from 'src/assets/controls/video.svg';
import raiseHand from 'src/assets/controls/raise-hands.svg';
import participantsIcon from 'src/assets/controls/participants.svg';
import settingsIcon from 'src/assets/controls/settings.svg';
import disconnectIcon from 'src/assets/controls/disconnect.svg';

function Controls(props) {
  return (
    <div style={styles.container} className="jc-center">
      <div style={{
          ...styles.button,
          ...(props.current['audio']? styles.enabled : styles.disabled),
          ...(props.current['muted']? styles.muted : {} )
        }}
        onClick={()=>{
          if (!props.current['muted'])
            props.onUpdate('audio', !props.current['audio'])
        }}>
        <img src={audioIcon} style={styles.icon} />
        {
          !props.current['audio'] &&
            <div style={styles.overlay} className="text-stroke">{'╲'}</div>
        }
      </div>
      <div style={{
          ...styles.button,
          ...(props.current['video']? styles.enabled : styles.disabled)
        }}
        onClick={()=>props.onUpdate('video', !props.current['video'])}>
        <img src={videoIcon} style={{...styles.icon, marginLeft: 3}} />
        {
          !props.current['video'] &&
            <div style={styles.overlay} className="text-stroke">{'╲'}</div>
        }
      </div>
      <div style={{
          ...styles.button,
          ...(
            props.current['handraise']?
              styles.enabled : styles.disabled_2
            )
        }}
        onClick={()=>props.onUpdate('handraise', true)}>
        <img src={raiseHand} style={{...styles.icon, marginLeft: 2}} />
      </div>
      <Settings open={props.current['settings']} config={props.settings}>
        <div style={{
            ...styles.button,
            ...(
              props.current['settings']?
                styles.enabled : styles.disabled_2
            )
          }}
          onClick={()=>props.onUpdate('settings', !props.current['settings'])}>
          <img src={settingsIcon} style={{...styles.icon, marginLeft: 1}} />
        </div>
      </Settings>
      <div style={{
          ...styles.button,
          backgroundColor: theme.disabled
        }}
        onClick={()=>props.onUpdate('joined', !props.current['joined'])}>
        <img src={disconnectIcon} style={styles.icon} />
      </div>
      <div style={{
          ...(
            (window.innerWidth > window.innerHeight)?
              {
                position: 'absolute',
                right: 24,
              }:{}
          ),
          ...styles.button,
          ...(
            props.current['participants']?
              styles.enabled : styles.disabled_2
          )
        }}
        onClick={()=>props.onUpdate('participants', !props.current['participants'])}>
        <img src={participantsIcon} style={{...styles.icon, marginLeft: 1}} />
      </div>
    </div>
  )
}

const theme = {
  enabled: '#8F989E',
  disabled: '#EE2935'
}

const styles = {
  container: {
    display: 'flex',
    width: '100%',
      },
  button: {
    margin: 8,
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  enabled: {
    backgroundColor: theme.enabled
  },
  disabled: {
    backgroundColor: theme.disabled
  },
  disabled_2: {
    backgroundColor: `${theme.enabled}80`,
  },
  muted: {
    opacity: 0.4
  },
  icon: {
    height: 30
  },
  overlay: {
    position: 'absolute',
    color: '#fff',
    fontSize: 16,
    transform: 'rotate(-2deg)',
    marginTop: -1,
  }
}

export default Controls;
