import { loadUserDataFromSession } from "./hooks/session-data";

export const BASE_URL = "https://api.routes2roots.com";
export const auth = loadUserDataFromSession("r2rToken");

export const IMAGE_BASE_URL = "https://routes2roots.com/images/";
export const ARTIST_IMAGE_BASE_URL =
  "https://routes2roots.com/uploads/faculties/";
export const CLASS_CURRICULUM_URL =
  "https://routes2roots.com/uploads/curriculum/";
export const CLASS_SCHEDULER_URL =
  "https://routes2roots.com/uploads/schedules/";

export const THUMBNAIL_URL = "//i.ytimg.com/vi/";
export const THUMBNAIL_URL_QUALITY = "/hqdefault.jpg";
