import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-container ai-center jc-center" style={styles.container}>
      <img
        src={require("src/assets/app_logo.png").default}
        style={styles.app_logo}
      />
      <div class="mainCard">
        <div class="imgWrap" style={styles.wrapImg}>
          <img
            src={require("src/assets/Finl.jpg").default}
            alt=""
            style={styles.image}
          />
        </div>
        <div className="card ai-center elevated" style={styles.card}>
          <h1>Something is not right...</h1>
          <br />
          <br />
          <img
            src={require("src/assets/404.svg").default}
            alt=""
            style={styles.errroImage}
          />
          <br />
          <br />
          <h5 align="center" style={{ maxWidth: "28rem" }}>
            Page you are trying to open does not exist. You may have mistyped
            the address, or the page has been moved to another URL. If you think
            this is an error contact support.{" "}
          </h5>
          <br />
          <br />
          <button
            type="secondry"
            style={styles.button}
            onClick={() => navigate("/")}
          >
            Get back to home page
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    overflow: "hidden",
    backgroundColor: "#E4E4E4",
  },
  wrapImg: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  errroImage: {
    width: "80%",
  },
  card: {
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    alignSelf: "center",
  },
  app_logo: {
    position: "absolute",
    top: 32,
    right: 32,
    height: 42,
  },
};

export default ErrorPage;
